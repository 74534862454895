$size: 150px;


@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}


@mixin animation-duration($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

.arrow_bounce {
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: $size;
  margin: 0 auto;
  margin-left: - $size / 2;

  @include keyframes(bounce) {
    0%, 20%, 50%, 80%, 100% {
      @include transform(translateY(0));
    }
    40% {
      @include transform(translateY(-30px));
    }
    60% {
      @include transform(translateY(-15px));
    }
  }

  .arrow, .arrow2 {
    position: absolute;

    background: transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .arrow {
    bottom: 0;
    width: $size;
    height: $size;
  }

  .arrow2 {
    bottom: 30px;
  }

  .bounce {
    @include animation(bounce 1.5s infinite);
  }
}
